<template>
  <div id="app">
    <el-container>
      <el-header style="background-color: #355cb5; height: 133px">
        <img
          id="hynyly"
          src="./assets/hynyly2.png"
          style="
            float: left;
            height: 123px;
            margin-left: -20px;
            padding-top: 5px;
          "
        />
      </el-header>
      <el-header
        style="background-color: #92b5f5; height: 60px; font-size: 30px"
      >
        <div style="float: left; padding-top: 8px; color: white">
          CO<SUB>2</SUB>混合流体热力学性质数据库
        </div>
      </el-header>
      <el-container v-bind:style="{ height: containerHeight + 'px' }">
        <el-aside width="260px" style="height: 100%">
          <div
            style="
              width: 100%;
              height: 100%;
              background-color: #ffffff;
              float: left;
            "
          >
            <el-menu class="el-menu-vertical-demo" @select="handleSelect">
              <el-menu-item
                index="/co2h2omidu"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span style=""
                  >CO<sub style="padding-top: 5px">2</sub
                  >咸水混合流体-密度</span
                >
              </el-menu-item>
              <el-menu-item
                index="/co2h2okuosan"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>咸水混合流体-扩散系数</span>
              </el-menu-item>
              <el-menu-item
                index="/co2h2ojiemian"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>咸水混合流体-界面张力</span>
              </el-menu-item>
              <el-menu-item
                index="/co2h2ojiechujiao"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>咸水混合流体-接触角</span>
              </el-menu-item>
              <!-- <el-menu-item index="/co2h2orongjiedu" class="el-menu-item-bottom"><span>CO<SUB>2</SUB>咸水混合流体-溶解度</span></el-menu-item>-->
              <!-- <el-menu-item index="/co2h2oph" class="el-menu-item-bottom"><span>CO<SUB>2</SUB>咸水混合流体-pH值</span></el-menu-item>-->
              <el-menu-item
                index="/co2chmidu"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>烷烃混合流体-密度</span>
              </el-menu-item>
              <el-menu-item
                index="/co2chjiemian"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>烷烃混合流体-界面张力</span>
              </el-menu-item>
              <el-menu-item
                index="/co2chkuosan"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>烷烃混合流体-扩散系数</span>
              </el-menu-item>
              <!-- <el-menu-item index="/co2chhunxiangyali" class="el-menu-item-bottom"><span>CO<SUB>2</SUB>烷烃合流体-混相压力</span></el-menu-item>-->
              <el-menu-item
                index="/co2ch4midu"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>CH<sub>4</sub>混合流体-密度</span>
              </el-menu-item>
              <el-menu-item
                index="/co2ch4kuosan"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>CH<sub>4</sub>混合流体-扩散系数</span>
              </el-menu-item>
              <el-menu-item
                index="/co2ch4misan"
                id="item-1"
                class="el-menu-item-bottom"
              >
                <span>CO<sub>2</sub>CH<sub>4</sub>混合流体-弥散系数</span>
              </el-menu-item>
            </el-menu>
          </div>
        </el-aside>
        <el-main style="padding: 0px; height: 100%; background-color: #f0f0f0">
          <router-view style="background-color: #ffffff"></router-view>
        </el-main>
      </el-container>
      <el-footer style="background-color: #92b5f5; height: 110px">
        <div style="width: 100%; height: 90px">
          <img
            src="./assets/DUTlogo.png"
            style="margin-left: -10px; padding-top: 10px"
          />
          <img
            src="./assets/xiaoxun.png"
            style="width: 650px; padding-top: 25px; padding-left: 20px"
          />
        </div>
        <div style="width: 100%; height: 20px">
          <p
            style="
              width: 400px;
              float: left;
              padding-left: 0px;
              color: white;
              text-align: left;
            "
          >
            地址:中国·辽宁省大连市甘井子区凌工路2号&nbsp;&nbsp;&nbsp;
          </p>
          <div style="float: right; width: 400px">
            <a
              rel="nofollow"
              href="https://beian.miit.gov.cn"
              target="_blank"
              style="padding-right: 10px; color: #1582c7"
              >辽ICP备2023009976号</a
            >
            <a
              href="https://beian.mps.gov.cn/#/query/webSearch?code=21029602000770"
              rel="noreferrer"
              target="_blank"
              style="color: #1582c7"
              >辽公网安备21029602000770</a
            >
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      containerHeight: 1000,
      screenHeight: 0,
    };
  },
  created() {
    this.handleSelect("/co2h2omidu", "");
  },
  mounted() {
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  watch: {
    screenHeight: {
      handler: function (val, oldVal) {
        // if(val<1024){
        //   this.containerHeight = 800
        // }else{
        this.containerHeight = val - 110 - 60 - 134;
        // }
      },
    },
  },
  methods: {
    handleSelect(key, value) {
      this.$router.push({
        path: key,
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  margin: 0 auto;
  padding: 0;
  min-width: 1000px;
  width: 1000px;
  background-color: white;
}
.a:active {
  background-color: orange;
}
body {
  background-image: url("./assets/backgroud.jpg");
  background-size: cover;
  /*background-repeat: no-repeat;*/
}
.el-menu-item.is-active {
  color: #0413e5;
  background-color: #f0f0f0;
  font-weight: bolder;
}
.el-menu-item-bottom {
  border-bottom: #92b5f5;
  border-bottom-style: inset;
}
#item-1 * {
  font-size: large;
  vertical-align: sub;
  height: 50px;
}
</style>

<style scoped>
.el-aside {
  overflow: hidden;
}
</style>