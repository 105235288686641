export function multiply(a, b) {
    // 相乘约束
    if (a[0].length !== b.length) {
        throw new Error();
    }
    let m = a.length;
    let p = a[0].length;
    let n = b[0].length;

    // 初始化 m*n 全 0 二维数组
    let c = new Array(m).fill(0).map(arr => new Array(n).fill(0));

    for (let i = 0; i < m; i++) {
        for (let j = 0; j < n; j++) {
            for (let k = 0; k < p; k++) {
                c[i][j] += a[i][k] * b[k][j];
            }
        }
    }
    console.log(c)
    return c;
}

export function last5(val) {
    return Number(val.toFixed(5))
}

export function zhuanhuan(val) {
    if (val > 0.00001) {
        return val.toFixed(5)
    } else {
        let x = val.toExponential()
        let xArray = x.split('e')
        return Number(xArray[0]).toFixed(5) + 'e' + xArray[1]
    }
}