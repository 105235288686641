<template>
  <div>
    <el-row
      style="
        background-color: #ffffff;
        height: 50px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: x-large;
        border-bottom: #92b5f5;
        border-bottom-style: inset;
      "
    >
      <el-col
        :span="4"
        style="
          text-align: left;
          padding-left: 15px;
          padding-top: 4px;
          color: #0253a5;
        "
        >输入参数</el-col
      >
      <el-col :span="8"></el-col>
      <el-col
        :span="8"
        style="
          text-align: left;
          padding-left: 50px;
          padding-top: 4px;
          color: #7f7f7f;
        "
        >范围</el-col
      >
    </el-row>

    <el-row
      :gutter="0"
      style="text-align: left; padding-top: 5px; padding-left: 10px"
    >
      <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-top: 5px;
          color: #0253a5;
          font-size: large;
        "
        >温度T</el-col
      >
      <el-col :span="8" style="padding-left: 10px">
        <el-input-number v-model="wendu" style="width: 90%" :controls="0" />
      </el-col>
      <el-col
        :span="8"
        style="
          padding-left: 20px;
          padding-top: 5px;
          color: #7f7f7f;
          font-size: large;
        "
        >274.15~424.15K</el-col
      >
    </el-row>
    <el-row :gutter="0" style="text-align: left; padding-left: 10px">
      <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-top: 5px;
          color: #0253a5;
          font-size: large;
        "
        >压力P</el-col
      >
      <el-col :span="8" style="padding-left: 10px">
        <el-input-number v-model="yali" style="width: 90%" :controls="0" />
      </el-col>
      <el-col
        :span="8"
        style="
          padding-left: 20px;
          padding-top: 5px;
          color: #7f7f7f;
          font-size: large;
        "
        >8~30MPa</el-col
      >
    </el-row>
    <el-row :gutter="0" style="text-align: left; padding-left: 10px">
      <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-top: 5px;
          color: #0253a5;
          font-size: large;
        "
        >NaCl浓度</el-col
      >
      <el-col :span="8" style="padding-left: 10px">
        <el-input-number v-model="nongdu" style="width: 90%" :controls="0" />
      </el-col>
      <el-col
        :span="8"
        style="
          padding-left: 20px;
          padding-top: 5px;
          color: #7f7f7f;
          font-size: large;
        "
        >0~4mol/kg</el-col
      >
    </el-row>
    <el-row :gutter="0" style="text-align: left; padding-left: 10px">
      <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-top: 5px;
          color: #0253a5;
          font-size: large;
        "
        >CO<sub>2</sub>质量分数</el-col
      >
      <el-col :span="8" style="padding-left: 10px">
        <el-input-number v-model="zhiliang" style="width: 90%" :controls="0" />
      </el-col>
      <el-col
        :span="8"
        style="
          padding-left: 20px;
          padding-top: 5px;
          color: #7f7f7f;
          font-size: large;
        "
        >0~0.04</el-col
      >
    </el-row>
    <el-row :gutter="0" style="text-align: left; padding-left: 10px">
      <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-top: 5px;
          color: #0253a5;
          font-size: large;
        "
        >选择模型</el-col
      >
      <el-col :span="8" style="padding-left: 10px">
        <el-select v-model="leixing" style="width: 90%">
          <el-option key="0" value="jingdian" label="经典模型" />
          <el-option key="1" value="diedai" label="迭代模型" disabled="true" />
        </el-select>
      </el-col>
      <el-col :span="8" style="padding-left: 15px">
        <el-button type="primary" @click="jisuan()">计算</el-button>
      </el-col>
    </el-row>
    <el-row
      style="
        margin-top: 10px;
        border-bottom: #92b5f5;
        border-bottom-style: inset;
      "
    ></el-row>

    <el-row
      style="
        height: 30px;
        margin-top: 10px;
        padding-bottom: 5px;
        text-align: left;
      "
    >
      <el-col
        :span="7"
        style="
          padding-top: 5px;
          padding-left: 15px;
          font-size: x-large;
          color: #0253a5;
        "
        >计算结果</el-col
      >
      <el-col
        :span="8"
        style="padding-top: 5px; font-size: x-large; color: #0253a5"
        >{{ jieguo }} g/cm<sup>3</sup></el-col
      >
    </el-row>

    <el-row
      style="
        margin-top: 20px;
        border-bottom: #92b5f5;
        border-bottom-style: inset;
      "
    ></el-row>

    <el-row
      style="padding-top: 30px; text-align: left; background-color: #f0f0f0"
    >
      <el-col
        :span="5"
        style="
          padding-top: 5px;
          padding-left: 15px;
          color: #7f7f7f;
          font-size: x-large;
        "
        >模型介绍：</el-col
      >
      <el-col
        :span="19"
        style="text-align: left; padding-top: 5px; font-size: large"
        >用于典型地质工况条件（1℃~150℃，8~30MPa），CO<sub>2</sub>-咸水溶液（NaCl浓度0~4mol/kg，CO<sub>2</sub>质量分数0~3%）密度计算。</el-col
      >
    </el-row>

    <!-- <el-row style="padding-top: 30px; text-align: left; background-color: #f0f0f0">
      <el-col :span="5" style="padding-top: 5px; padding-left: 15px; color: #7f7f7f; font-size: x-large">论文链接：</el-col>
      <el-col :span="19" style="padding-top: 5px; ">
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
        <el-link href="http://loec.dlut.edu.cn/" target="_blank" :underline="false" style="width: 100%; justify-content: left; font-size: large">论文一</el-link>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { zhuanhuan, last5 } from "../js/index";
export default {
  data() {
    return {
      wendu: 0.0,
      yali: 0.0,
      nongdu: 0.0,
      zhiliang: 0.0,
      leixing: "jingdian",
      jieguo: 0.0,
      s1: [0.99787, -1.705544e-4, -2.41136e-6],
      s2: [7.08426e-4, -7.6631e-7, -5.00726e-9],
      s3: [0.02893, 7.38756e-5, -4.25796e-7],
      s4: [0.01014, -1.53974e-4, 1.04055e-6],
      s5: [0.13141, 1.62044e-4, -3.56575e-6],
      s6: [-0.05288, 1.09421e-5, 7.20842e-7],
    };
  },
  methods: {
    jisuan() {
      var q = this.jiaoyan();
      if (q.length > 0) {
        ElMessage.error(q);
      } else {
        var jieguo = 0.0;
        if (this.leixing === "jingdian") {
          for (var i = 0; i < 3; i++) {
            var x0 = this.s2[i] * this.yali;
            var x1 = this.s3[i] * this.nongdu;
            var x2 = this.s4[i] * Math.pow(this.zhiliang, 1 / 3);
            var x3 = this.s5[i] * Math.pow(this.zhiliang, 1 / 2);
            var x4 = this.s6[i] * Math.pow(this.zhiliang, 1 / 3);
            var x =
              this.s1[i] +
              last5(x0) +
              last5(x1) +
              last5(x2) +
              last5(x3) +
              last5(x4);
            var y = Math.pow(this.wendu - 273.15, i);
            jieguo += x * y;
          }
        } else {
          jieguo = 0.0;
        }
        this.jieguo = zhuanhuan(jieguo);
      }
    },
    jiaoyan() {
      var result = [];
      if (this.wendu < 274.15 || this.wendu > 424.15) {
        result.push("温度");
      }
      if (this.yali < 8 || this.yali > 30) {
        result.push("压力");
      }
      if (this.nongdu < 0 || this.nongdu > 4) {
        result.push("NaCl浓度");
      }
      if (this.zhiliang < 0 || this.zhiliang > 4) {
        result.push("CO2质量分数");
      }
      var result_str = "";
      for (var i = 0; i < result.length; i++) {
        if (i === result.length - 1) {
          result_str += result[i].toString();
          result_str += "参数范围错误，请修改后重新计算！";
        } else {
          result_str += result[i].toString();
          result_str += "、";
        }
      }
      return result_str;
    },
  },
};
</script>

<style>
.el-input {
  --el-input-border-color: #92b5f5;
  --el-input-text-color: #0253a5;
}
</style>